@import '@zcode-ui/zumper-theme/styles/global/color.scss';
@import '@zcode-ui/zumper-theme/styles/global/mixins.scss';

.badge {
  display: inline-block;
  width: auto !important;
  margin: 0 0 0 4px;
  padding: 2px 5px 1px;

  color: $white;
  font-weight: 500;
  font-size: 10px;
  vertical-align: middle;

  background-color: $slateDark;
  border-radius: 2px;

  @include is-mobile {
    padding: 3px 5px 2px;

    font-size: 13px;
  }
}

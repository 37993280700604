@critical true;

input[type="search"].input {
  z-index: 5;

  width: 100%;
  height: 56px;
  padding: 24px 16px 9px;

  color: #0a0f1c;
  font-weight: 500;
  font-size: 16px;

  line-height: 20px;

  text-overflow: ellipsis;

  background: transparent;

  border: 0;
  border: 1px solid #ebeae6;
  border-radius: 6px;

  transition: width 200ms;

  &:hover {
    color: #000;
  }

  &::placeholder {
    color: var(--chakra-colors-gray-800);

    opacity: 1;
  }

  &:focus {
    border-color: var(--chakra-colors-neutral-500);
    outline: none;
    box-shadow: 0 0 0 4px var(--chakra-colors-neutral-200);

    &::placeholder {
      color: #726f6c;

      opacity: 1;
    }
  }

  &:not(:focus) {
    border-color: #ebeae6;
    outline: none;
  }
}

input[type="search"].inputEmptyState {
  max-width: 216px;
  height: 30px;

  color: var(--chakra-colors-gray-800);
  line-height: 1;
  line-height: 20px;
  text-overflow: ellipsis;

  background-color: transparent;

  border-color: transparent;
  border-radius: 6px;
  box-shadow: 0 0 0 4px transparent;
  cursor: pointer;

  &::placeholder {
    color: #0a0f1c;

    opacity: 1;

    &:focus {
      color: #0a0f1c;
    }
  }
}

/* clears the 'X' from Internet Explorer */
input[type="search"]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input[type="search"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

input[type="search"]:focus {
  border-color: transparent;

  box-shadow: 0 0 0 4px transparent;
}

.contextualMenu {
  position: relative;

  display: none;
  width: 392px;
  min-width: 264px;
  margin-top: 16px;
  padding: 16px 0 8px;

  background: #fff;
  border: 1px solid #ebeae6;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.04);

  cursor: default;

  pointer-events: none;

  @media (min-width: 992px) {
    width: 392px !important;
    max-height: 372px;
    overflow-y: auto;
  }

  &.show {
    z-index: 1500; // theme.$zIndexPopover;

    display: block;

    pointer-events: all;
  }
}

.searchIcon {
  display: none;
}

.spinnerIcon {
  position: absolute;
  top: 40%;
  left: 9px;
}

.closeButton {
  position: absolute;
  top: 50%;
  right: 16px;
  z-index: 5;

  width: 20px;
  height: 20px;
  padding: 0;

  background: transparent;
  border: 0;
  transform: translateY(-50%);
  cursor: pointer;
}

html .closeIconWithButton {
  position: absolute;
  top: 50%;
  right: 150px;

  height: 24px;
  padding: 0;

  background: transparent;
  border: 0;

  transform: translateY(-50%);
}

.inputBtn {
  position: relative;

  display: flex;
  align-items: center;
  height: 40px;
  padding: 10px 16px;

  color: #726f6c;
  font-size: 14px;
  text-align: left;

  cursor: pointer;

  > div {
    width: 100%;

    > div {
      overflow: hidden;

      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  &:only-child {
    margin-bottom: 0;
  }

  &:focus {
    outline: none;
  }

  @media (min-width: 771px) {
    &:hover,
    &.hover {
      color: #0a0f1c;
    }
  }
}

.secondaryText {
  display: none;
}

.locationText {
  position: relative;

  display: flex;
  align-items: center;
  max-width: 95%;
  height: 28px;

  color: #9b9793;
  font-size: 14px;

  border: 1px solid transparent;

  cursor: pointer;

  &:only-child {
    margin-bottom: 0;
  }

  &:focus {
    outline: none;
  }

  img {
    display: none;
  }

  @media (min-width: 771px) {
    &:hover,
    &.hover {
      color: #0a0f1c;
    }
  }

  &::after {
    position: absolute;
    top: 0;
    left: 0;

    height: 5px;

    border-left: 1px solid #fff;
    transform: translate(-1px, -1px);

    content: "";
  }

  &::before {
    position: absolute;
    bottom: 0;
    left: 0;

    height: 5px;

    border-left: 1px solid #fff;
    transform: translate(-1px, 1px);

    content: "";
  }

  @media (max-width: 770px) {
    font-size: 18px;
  }
}

@import "@zcode-ui/zumper-theme/styles/global/color.scss";
@import "@zcode-ui/zumper-theme/styles/global/grid.scss";
@import "@zcode-ui/zumper-theme/styles/global/mixins.scss";
@import "@zcode-ui/zumper-theme/styles/global/typography.scss";
@import "@zcode-ui/zumper-theme/styles/global/z_index.scss";

@critical true; /* stylelint-disable-line scss/at-rule-no-unknown */

input[type="search"].input {
  width: 100%;
  height: 100%;
  padding: 18px 24px;

  line-height: calc(100% - 4px);

  border: 0;
  border-radius: 4px;

  appearance: none;

  @include is-mobile {
    width: calc(100% - 10px);
    height: 40px;
    padding: 18px 0;

    color: #0a0f1c;

    font-size: 16px;
    line-height: 24px;
  }

  &:focus {
    border-color: var(--chakra-colors-neutral-500);
    outline: none;
    box-shadow: 0 0 0 4px var("--chakra-colors-neutral-200");
  }

  @media (max-width: $breakpoint-mobile) {
    text-size-adjust: none;
    /* stylelint-disable-next-line property-no-vendor-prefix */
    -webkit-text-size-adjust: none;

    &:focus {
      text-size-adjust: none;
      /* stylelint-disable-next-line property-no-vendor-prefix */
      -webkit-text-size-adjust: none;
    }

    &:active {
      text-size-adjust: none;
      /* stylelint-disable-next-line property-no-vendor-prefix */
      -webkit-text-size-adjust: none;
    }
  }
}

.label {
  width: 100%;
  height: 100%;
}

input[type="search"].input::placeholder {
  vertical-align: center;
}

.container {
  position: relative;

  width: 100%;
  height: 100%;

  cursor: text;

  &:focus {
    outline: none;
  }
}

.mobileInputContainer {
  position: relative;

  display: flex;
  height: 64px;
}

.searchForm {
  width: 100%;
  height: 100%;
  height: 38px;
  margin: 0 10px 0 16px;

  @include is-mobile {
    height: inherit;
    margin: 0;
    margin-left: 8px;
  }

  &.hideSearchForm {
    display: none;
  }
}

.searchFormModal {
  position: relative;

  display: flex;
  flex: 1 1 auto;
  align-items: center;
}

html .searchFormWithButton {
  position: relative;

  display: flex;
  width: 100%;

  vertical-align: bottom;

  input[type="search"].input {
    border-radius: 5px 0 0 5px;

    &:focus {
      box-shadow: none;
    }
  }

  @media (max-width: $breakpoint-580) {
    display: block;
    width: 100%;
    height: 46px;
    margin-bottom: 12px;

    input[type="search"].input {
      border-radius: 4px;
    }
  }
}

.contextualMenu {
  position: relative;

  left: 16px;

  display: none;

  width: 800px;
  margin-top: 5px;

  background: $white;
  border-radius: 3px;
  box-shadow: 0 0 0 1px rgba(6, 44, 82, 0.1),
    0 2px 16px 0 rgba(33, 43, 54, 0.08);
  cursor: default;

  pointer-events: none;

  @media (max-width: 770px) {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: $zIndexPopover;

    width: 100%;
    height: 100vh;
    margin: 0;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  &.show {
    z-index: $zIndexPopover;

    display: block;

    pointer-events: all;
  }

  &.hasMaxWidth {
    max-width: 100%;
  }

  @media (max-width: 996px) {
    width: 668px;
  }

  @media (max-width: 872px) {
    width: 568px;
  }

  @media (max-width: $breakpoint-mobile) {
    width: 100%;
  }
}

.spinnerIcon {
  position: absolute;
  left: 14px;

  width: 24px;
  height: 24px;

  transform: translate(10px, -50%);

  @include is-mobile {
    width: 20px;
    height: 20px;

    transform: translateY(-50%);
  }
}

html .spinnerIcon {
  top: 33%;
  left: 35px;

  @include is-mobile {
    left: 20px;

    width: 12px;
    height: 12px;
  }
}

.searchIconSmall {
  @media (max-width: $breakpoint-374) {
    left: 8px;

    width: 13px;
    height: 13px;
  }
}

html .inputSearchButton {
  width: 150px;
  height: 100%;
  padding: 8px 15px 6px;

  font-weight: $bold;
  font-size: 20px;
  line-height: 19px;

  border-radius: 0 5px 5px 0;

  @media (max-width: $breakpoint-580) {
    width: 100%;

    border-radius: 4px;
  }
}

/* clears the 'X' from Internet Explorer */
input[type="search"]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input[type="search"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

html .inputBtnsContainer {
  @include is-mobile {
    margin-top: 32px;
    padding-bottom: 10px;
  }

  .useMyLocation {
    margin-bottom: 48px;
  }

  .recentSearchHeader {
    height: 16px;
    margin-bottom: 24px;
    padding: 10px 0 10px 24px;

    font-size: 11px;
    line-height: 16px;
  }

  .inputBtn {
    position: relative;

    display: flex;
    align-items: center;
    height: 40px;
    padding: 10px 0 10px 16px;

    cursor: pointer;

    @media (max-width: 770px) {
      height: 24px;

      margin-bottom: 24px;

      color: var(--chakra-colors-gray-800);
      font-size: 14px;
    }

    @include is-desktop {
      // stylelint-disable-next-line max-nesting-depth
      &:hover,
      &.hover {
        background-color: $slateLighter;
        border-radius: 3px 3px 0 0;
      }
    }
    // stylelint-disable-next-line selector-max-compound-selectors
    img {
      display: none;
      // stylelint-disable-next-line max-nesting-depth
      @media (max-width: 770px) {
        display: block;
        width: 20px;

        height: 20px;
      }
    }
  }

  &:only-child {
    margin-bottom: 0;
  }

  &:focus {
    outline: none;
  }
}

.locationText {
  max-width: 95%;

  padding-left: 15px;

  color: #2a2b2c;
  font-size: 14px;

  @media (max-width: 770px) {
    padding-left: 8px;

    color: var(--chakra-colors-gray-800);
    font-size: 14px;
  }
}

html .backBtn {
  display: flex;
  align-items: center;
  padding: 0 15px 0 10px;

  background: 0;
  border: 0;

  @media (max-width: 770px) {
    padding: 0;
    padding-left: 16px;
  }
}

html .contextualMenuWithButton {
  width: 100%;
}

.closeButton {
  position: absolute;
  top: 50%;
  right: 24px;

  width: 20px;
  height: 20px;
  padding: 0;

  background: transparent;
  border: 0;

  transform: translateY(-50%);

  cursor: pointer;

  @include is-desktop {
    right: 50px;
  }

  @media (max-width: 770px) {
    display: flex;
    align-items: center;

    background: 0;
    border: 0;

    img {
      width: 20px;
      height: 20px;
    }
  }
}

html .closeIconWithButton {
  position: absolute;
  top: 50%;
  right: 150px;

  height: 24px;
  padding: 0;

  background: transparent;
  border: 0;

  transform: translateY(-50%);
}

.primaryText {
  padding-left: 8px;
}

.secondaryText {
  // stylelint-disable-next-line scss/at-extend-no-missing-placeholder
  @extend .ellipsize;
  color: $gray-70;
  font-size: 14px;
}

// CITY FOOTER LINK CUSTOM STYLES
.cityFooter {
  width: 100%;
  padding: 10px 15px;
}

.cityFooterHeader {
  margin: 0;
  padding-bottom: 14px;

  color: $gray-70;
  font-weight: $bold;

  font-size: 13px;
  line-height: 18px;

  letter-spacing: 1px;
  text-transform: none;
}

.cityFooterTopCities {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 130px;

  @media (max-width: $breakpoint-1024) {
    flex-direction: row;
    height: initial;
  }
}

.cityFooterLink {
  // stylelint-disable-next-line scss/at-extend-no-missing-placeholder
  @extend .ellipsize;
  flex: 0 0 20%;
  width: 20%;
  padding-top: 1px;
  padding-right: 10px;

  color: $slateDark;
  font-size: 13px;
  line-height: 13px;

  letter-spacing: 0.2px;

  &:hover {
    color: $hoverBlue;
    text-decoration: none;
  }

  @media (hover: none) {
    &:hover {
      color: $slateDark;
      text-decoration: none;
    }
  }

  @media (max-width: $breakpoint-1024) {
    flex: 0 0 50%;
    width: initial;
    height: 30px;
  }
}

.children {
  &:focus {
    outline: none;
  }
}

// CONTEXTUAL FOOTER CUSTOM STYLES
.contextualFooterContainer {
  padding: 0 15px 15px;

  @include is-mobile {
    padding: 15px 15px 15px 0;

    border-top: 1px solid $slate;
  }

  article {
    flex: 1 1 auto;
    max-width: 33.33%;

    @include is-mobile {
      max-width: 100%;
    }
  }
}

.header,
.item {
  // stylelint-disable-next-line scss/at-extend-no-missing-placeholder
  @extend .ellipsize;
  color: $inkLight;
  font-size: 13px;
  line-height: 25px;

  @include is-mobile {
    font-size: 16px;
    line-height: 1.5em;
  }

  @media (max-width: 872px) {
    font-size: 12px;
  }
}

.header {
  padding: 5px 0;

  font-weight: $regular;

  @include is-mobile {
    padding: 10px 0;

    font-weight: $medium;
  }
}

.item {
  display: block;

  font-weight: $light;

  @include is-mobile {
    line-height: 38px;
  }
}

@import "@zcode-ui/zumper-theme/styles/global/z_index.scss";

@critical true;

input[type="search"].input {
  z-index: 5;

  width: 100%;
  height: 56px;
  padding: 18px 44px;
  overflow: hidden;

  color: #0a0f1c;

  font-size: 16px;
  line-height: 24px;

  background-color: transparent;

  border: 0;
  border: 1px solid #ebeae6;
  border-radius: 6px;

  &:focus {
    border: 1px solid #80a4b5;
    outline: none;
    box-shadow: 0 0 0 4px #c9dade;
  }

  &::placeholder {
    color: #726f6c;

    opacity: 1;
  }
}

/* clears the 'X' from Internet Explorer */
input[type="search"]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input[type="search"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

.contextualMenu {
  position: relative;

  display: none;

  width: auto;
  min-width: 264px;
  margin-top: 16px;
  padding: 16px 0 8px;

  background: #fff;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.04);

  cursor: default;

  pointer-events: none;

  &.show {
    z-index: 1500; // theme.$zIndexPopover;

    display: block;

    pointer-events: all;
  }

  &.hasMaxWidth {
    max-width: 100%;
  }
}

.searchIcon {
  position: absolute;
  top: 50%;
  left: 16px;
  z-index: $zIndexOverlay;

  width: 20px;

  transform: translateY(-50%);
}

.spinnerIcon {
  position: absolute;
  top: 40%;
  left: 16px;
  z-index: $zIndexOverlay;
}

.closeButton {
  position: absolute;
  top: 50%;
  right: 16px;

  z-index: 6;

  width: 20px;
  height: 20px;
  padding: 0;

  background: transparent;
  border: 0;

  transform: translateY(-50%);

  cursor: pointer;
}

html .closeIconWithButton {
  position: absolute;
  top: 50%;
  right: 150px;

  height: 24px;
  padding: 0;

  background: transparent;
  border: 0;

  transform: translateY(-50%);
}

.locationIcon {
  position: absolute;
  top: 50%;
  right: 16px;

  transform: translateY(-50%);
}

.inputBtn {
  position: relative;

  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 16px;

  color: #454442;
  font-size: 14px;
  line-height: 20px;

  cursor: pointer;

  &:only-child {
    margin-bottom: 0;
  }

  &:focus {
    outline: none;
  }

  @media (min-width: 771px) {
    &:hover,
    &.hover {
      color: #0a0f1c;

      background: #f9f9f6;
    }
  }

  .locationIcon {
    display: inline-block;
    margin-right: 10px;
  }
}

.secondaryText {
  display: none;
}

.locationText {
  max-width: 95%;

  padding-left: 15px;

  color: #2a2b2c;
  font-size: 18px;

  @media (max-width: 770px) {
    font-size: 18px;
  }
}

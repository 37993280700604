@import "@zcode-ui/zumper-theme/styles/global/color.scss";
@import "@zcode-ui/zumper-theme/styles/global/typography.scss";
@import "@zcode-ui/zumper-theme/styles/global/grid.scss";

@critical;

.container {
  color: $white;

  background-color: $blackLighter;

  @media (max-width: 767px) {
    min-height: 1060px;
  }

  @media (max-width: 639px) {
    min-height: 1230px;
  }

  @media (max-width: 429px) {
    min-height: 1258px;
  }

  @media (max-width: 359px) {
    min-height: 1580px;
  }
}

.footerRow {
  display: flex;
  justify-content: space-between;

  @media (max-width: $breakpoint-975) {
    flex-wrap: wrap;
  }
}

.footerMain {
  padding: 45px 0 36px;

  border-bottom: 1px solid #405161;
}

.column {
  width: 100%;
}

.column > a {
  // stylelint-disable-next-line scss/at-extend-no-missing-placeholder
  @extend .bodySmall;

  display: block;

  color: $white;
  font-weight: $medium;
  font-size: 16px;
  line-height: 1.5em;

  &:hover {
    color: $white;
  }

  @media (max-width: $breakpoint-mobile) {
    line-height: 2.1em;
  }
}

.left {
  flex: 0 1 auto;
  width: 280px;
  padding-right: 15px;

  @media (max-width: $breakpoint-975) {
    flex: 1 0 100%;
    max-width: none;
    margin-bottom: 36px;
    padding-right: 0;
  }
}

.right {
  flex: 0 0 280px;

  @media (max-width: $breakpoint-975) {
    flex: 1 0 50%;
    max-width: none;
    margin-top: 36px;
  }

  @media (max-width: $breakpoint-640) {
    margin-top: 36px;
  }
}

// stylelint-disable-next-line no-descending-specificity
.badges > a {
  display: inline-block;

  opacity: 0.9;

  &:hover {
    opacity: 1;
  }

  @media (hover: none) {
    &:hover {
      opacity: 0.9;
    }
  }
}

.privacy {
  @media (max-width: 640px) {
    margin-top: 36px;
  }
}

.middle {
  flex: 1 0 110px;
  padding-right: 15px;

  @media (max-width: $breakpoint-975) {
    flex: 1 0 25%;
    max-width: none;
  }

  @media (max-width: $breakpoint-640) {
    flex: 1 0 50%;
  }
}

.bigHeader {
  margin-top: 0;
  margin-bottom: 15px;
}

.header {
  // stylelint-disable-next-line scss/at-extend-no-missing-placeholder
  @extend .bodyMicro;
  margin-top: 0;

  margin-bottom: 15px;

  color: $gray-30;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.secondHeader {
  margin-top: 38px;

  @media (max-width: $breakpoint-640) {
    margin-top: 42px;
  }

  @media (max-width: $breakpoint-mobile) {
    margin-bottom: 22px;
  }
}

.badges {
  min-width: 280px;

  @media (max-width: $breakpoint-320) {
    min-width: 215px;
  }
}

.description {
  // stylelint-disable-next-line scss/at-extend-no-missing-placeholder
  @extend .bodyMedium;

  margin-bottom: 8px;

  font-size: 16px;
  line-height: 1.5em;
}

.copyright {
  margin-bottom: 20px;

  color: $white;

  font-size: 16px;

  @media (max-width: $breakpoint-mobile) {
    margin-bottom: 25px;
  }
}

.followUs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 120px;
  height: 20px;
}

// stylelint-disable-next-line no-descending-specificity
.followUs > a {
  opacity: 0.9;

  &:hover {
    opacity: 1;
  }

  @media (hover: none) {
    &:hover {
      opacity: 0.9;
    }
  }
}

.accessibility {
  margin-top: 64px;

  @media (max-width: $breakpoint-mobile) {
    margin-top: 38px;
  }
}

.apple {
  margin-right: 10px;

  color: $white;
}

.bottom {
  flex: initial;
  justify-content: space-between;
  padding: 15px 0;

  color: $gray-30;
  font-size: 16px;
  line-height: 1.5em;

  @media (max-width: $breakpoint-mobile) {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}

.fairHousing {
  flex: 0 0 auto;
  margin: 0 72px 12px 0;

  @media (max-width: 520px) {
    margin-right: 0;
  }
}

.house {
  display: inline-block;
  margin-right: 12px;
}

.builtBy {
  display: flex;

  @media (max-width: 1200px) {
    flex: 1 1 50%;
  }
}

.bridge {
  position: relative;
  top: 4px;

  flex: 0 0 auto;

  margin-right: 12px;
}

.joinUs {
  color: $gray-30;
  text-decoration: underline;

  &:hover {
    color: $white;
  }

  @media (hover: none) {
    &:hover {
      color: $lightGray;
    }
  }
}

.noWrap {
  white-space: nowrap;
}

.footerLink {
  // stylelint-disable-next-line scss/at-extend-no-missing-placeholder
  @extend .bodySmall;

  color: $white;
  font-weight: $medium;
  font-size: 16px;
  line-height: 1.5em;

  &:hover {
    color: $white;
    text-decoration: underline;
  }

  @media (max-width: $breakpoint-mobile) {
    line-height: 2.1em;
  }
}

.isFiltersView {
  visibility: hidden;
}

.trademark {
  position: relative;
  top: -0.5em;

  font-size: 12px;
}

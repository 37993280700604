.text::after {
  display: inline-block;

  width: 0;
  overflow: hidden;

  vertical-align: bottom;

  -webkit-animation: ellipsis steps(4, end) 1000ms infinite;
  animation: ellipsis steps(4, end) 1000ms infinite;

  content: "\2026";
}

@keyframes ellipsis {
  to {
    width: 1rem;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 1rem;
  }
}
